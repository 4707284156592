<template>
  <div>
    <b style="font-size: 300%">{{ temps }}</b>
    <div class="d-flex align-items-center justify-content-center w-100 h-100">
      <div class="flex-basis-25 div-10h" >
        <img src="../../assets/imagesPause/10h.jpg" />
        <div :class="pauseCourante. id == 1 ? '' : 'div-grisee'"></div>
      </div>
      <div class="flex-basis-25 div-12h" :class="pauseCourante. id == 2 ? '' : 'div-grisee'">
        <img src="../../assets/imagesPause/12h.jpg" />

      </div>
      <div class="flex-basis-25 div-15h" :class="pauseCourante. id == 3 ? '' : 'div-grisee'">
        <img src="../../assets/imagesPause/15h.jpg" />

      </div>
      <div class="flex-basis-25 div-17h" :class="pauseCourante.id == 4 ? '' : 'div-grisee'">
        <img src="../../assets/imagesPause/17h.jpg" />

      </div>
    </div>
  </div>
</template>

<script src="./compteurPause.js"></script>

<style scoped>
div{
     overflow: hidden;
}

.div-grisee{
     background-color: rgba(78, 76, 76, 0.43);
     z-index: 100;
     height: 100vh;
     width: 100%;
     top:0;
     position: absolute;
}

.flex-basis-25{
     position: relative;
}
</style>