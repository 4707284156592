
import moment from 'moment'

export default {
     name: "CompteurPause",

     data() {
          return {
               pauses: [
                    {id: 1, debut : moment().hour(10).minutes(0), fin : moment().hour(10).minutes(15)},
                    {id: 2, debut : moment().hour(12).minutes(15), fin : moment().hour(13).minutes(30)},
                    {id: 3, debut : moment().hour(15).minutes(30), fin : moment().hour(15).minutes(45)},
                    {id: 4, debut : moment().hour(17).minutes(45), fin : moment().hour(8).minutes(0)}
               ],

               temps: null,
               interval: null
          }
     },

     computed:{
          pauseCourante(){
               var retour = this.pauses[0];
               this.pauses.forEach(element => {
                    if(moment(element.debut,"YYYYMMDD").diff(moment(), 'seconds') >= 0 && moment(element.fin,"YYYYMMDD").diff(moment(), 'seconds') < 0 ) retour =  element;
               })
               return retour;
          }
     },

     methods: {

          initialiserPauses() {
               var tmpTemps = moment(this.pauseCourante.debut, "YYYYMMDD").diff(moment(), 'seconds');
               this.interval = setInterval(() => {
                    tmpTemps -= 1;
                    if(!document.hasFocus()){
                         tmpTemps = moment(this.pauseCourante.debut, "YYYYMMDD").diff(moment(), 'seconds');
                    }
                    this.temps = moment(0).startOf('day').seconds(tmpTemps).format('HH:mm:ss');

               }, 1000)
          }

     },

     beforeUnmount() {
          clearInterval(this.interval)
     },

     

     created() {
          moment.locale("fr");

          this.pauses.forEach(element => {
               element.debut.seconds(0);
               element.fin.seconds(0);
          });

     this.initialiserPauses();
     }
}